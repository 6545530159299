const CourseSettingsMenu = [
  {
    heading: "menu",
    route: "/training-program",
    pages: [
      {
        heading: "Program Designation",
        route: "/training-program/program-designation",
        fontIcon: "fas fa-road fs-3></i>",
        svgIcon: "/media/icons/duotune/general/gen027.svg",
      },
      {
        heading: "Program Package",
        route: "/training-program/program-package",
        fontIcon: "fas fa-check-square fs-3",
        svgIcon: "/media/icons/duotune/general/gen027.svg",
      },
      {
        heading: "Program Name",
        route: "/training-program/program-name",
        fontIcon: "fas fa-font",
        svgIcon: "/media/icons/duotune/general/gen027.svg",
      },
      {
        heading: "Program Sector",
        route: "/training-program/program-sector",
        fontIcon: "fas fa-exclamation-circle fs-3",
        svgIcon: "/media/icons/duotune/general/gen027.svg",
      },
      {
        heading: "Program Trade",
        route: "/training-program/program-trade",
        fontIcon: "bi-shield-check bi fs-3",
        svgIcon: "/media/icons/duotune/general/gen027.svg",
      },
      {
        heading: "Program Type",
        route: "/training-program/program-type",
        fontIcon: "fas fa-sitemap",
        svgIcon: "/media/icons/duotune/general/gen027.svg",
      },
      {
        heading: "Program Venue",
        route: "/training-program/program-venue",
        fontIcon: "fas fa-map-signs fs-3",
        svgIcon: "/media/icons/duotune/general/gen027.svg",
      },
      {
        heading: "Program Organization",
        route: "/training-program/program-organization",
        fontIcon: "fas fa-university",
        svgIcon: "/media/icons/duotune/general/gen027.svg",
      },
    ],
  },
];

export default CourseSettingsMenu;
